import mnemonic from './mnemonic';
import { getWord } from './generateSeed';

const { isInMnemonic } = mnemonic;

/* 
  создает seed  массив где будут хранится наши данные
  @param {num} pages - кол-во страниц на которые разбит сид
*/
export const getSeedArrayLength = pages => {
  let seedArray = new Array(pages);

  for (let i = 0; i < seedArray.length; i++) {
    seedArray[i] = '';
  }

  return seedArray;
}
/* 
  возвращает массив  arr.length, которого говорит сколько у нас будет слов в последней колонке, а значение в массиве говорит сколько слов будет надо для получение каждого слова
  например:
  15 слов
  3 карточки
  вернут массив  [3, 3, 3, 3, 3, 3, 3, 2];
*/
export const getWordsOnEncode = (seed, wordsCount) => {
  const pages = seed.length;
  const encodedWords = new Array(Math.ceil(wordsCount / (pages - 1)));

  for (var i = 0; i < encodedWords.length - 1; i++) {
    encodedWords[i] = pages;
  }

  if (wordsCount % (pages - 1) === 0) {
    encodedWords[encodedWords.length - 1] = pages;
  } else {
    encodedWords[encodedWords.length - 1] = 1 + (wordsCount % (pages - 1));
  }

  return encodedWords;
}
/* 
  Метод текста из карточек на элементы массива
  @param {array} seed - массив элементы которого текст каждой карточки
*/
export const getSeedElemAsArray = (seed) => {
  return seed.map((item, i, arr) => {
    if (item.length > 0) {
      return arr[i].split(' ');
    } else {
      return [];
    }
  });
}
/* 
  метод возвращает массив который содержит информацию о том сколько слов в каждой карточке
*/
export const getWordPerCard = (wordCount, pages) => {
  
  let word = 0;
  let wordsOnColumn = [];
  // определяю максимально число слов в колонке
  let max_word = Math.ceil(wordCount / (pages - 1));

  for (let i = 1; i <= max_word; i++) {
    for (let card = 0; card < pages - 1; card++) {

      if (word < wordCount) {
        wordsOnColumn[card] = i;

        word++
      }
    }
  }
  // присваиваю длину последней колонке 
  wordsOnColumn[pages - 1] = max_word;

  return wordsOnColumn;
}
///////////// методы отвечающие за востановление сида
export const getRestoreSeed = (seed, wordsCount) => {
  const seedArray = getSeedElemAsArray(seed);
  const encodedWords = getWordsOnEncode(seed, wordsCount);
  
  let restoreSeed = [];
  // этот массив нужен для проверки тут будут слова из последней карточки
  let lastPage = [];

  for (let i = 0; i < encodedWords.length; i++) {
      for (let j = 0; j < seedArray.length; j++) {

        if(j === seedArray.length - 1) {
          lastPage.push(seedArray[j][i])
        } else {
          restoreSeed.push(seedArray[j][i]);
        }
      }
  }
  
  restoreSeed = restoreSeed.join(' ').toLowerCase();
  
  return {
    restoreSeed,
    lastPage
  }
}

/* 
  @param {array} - массив содержащий слова из карточек
  @param {number} - порядковый номер слова в карточке
  @param {number} len - число слов необходимое, для получения закодированного слова
*/
let currentPostition = 0;
export const checkThatAvailable = (arr_words, j, len) => {
  let count = len;
  for (let i = 0; i < arr_words.length; i++) {
    if (arr_words[i][j] === '') {
      count--;
      currentPostition = i;
    }
  }
  return count;
}

export const canRestoreSeed = (seedArray, wordCount, wordsOnColumn) => {
  if(!seedArray) return seedArray;
  const emptyCards = seedArray.filter((item, index) => {
    if(!item) return false;
    return item.length !== wordsOnColumn[index];
  });
  if(!emptyCards.length) return true;
  return !(emptyCards.length - 1);
}

// проверяю что если разбить полученный сид получатся слова из последней карточки
export const checkRestoreSeedIsValid = (arr, wordCount, wordsOnColumn) => {
  let empty = [];
  let empty_card = 0;
  let restoreSeed = '';
  // все колонки кроме последней должны быть заполнены иначе будет сообщение об ошибке
  for(let card = 0; card < arr.length; card++) {
    let count = 0;
    for(let i = 0; i < wordsOnColumn[card]; i++) {
      if(arr[card][i] !== '') {
        count++
      }
    }

    empty[card] = count === wordsOnColumn[card];
    if (!empty[card] && card !== arr.length - 1) {
      empty_card++;
    }
  }
  //
  let w = 1;
  for (let card = 0; card < arr.length - 1; card++) {
    for (let i = 0; i < wordsOnColumn[card]; i++) {
      if (w <= wordCount && isInMnemonic(arr[card][i]) > -1) {
        w++;
      } else if (isInMnemonic(arr[card][i]) === -1) {
        return false;
      }
    }
  }
  
  // если все карточки заполнены
  if (empty_card === 0) {
    let w = 1;
    for (let i = 0; i < wordsOnColumn[0]; i++) {
      for (let j = 0; j < arr.length - 1; j++) {
        if (w <= wordCount) {
          restoreSeed += arr[j][i] + ' ';
          w++;
        }
      }
    }
    return restoreSeed;
  } else {
    return false;
  }
}

export const getEmptyColumn = (seed, wordCount, wordsOnColumn) => {
  const seedArray = getSeedElemAsArray(seed).map(words => words.filter(word => word !== ""));;
  let arr = seed.map((item, index) => {
    const wordsCount = wordsOnColumn[index];
    const card = seedArray[index];
    let words = [];
    for(let i = 0; i < wordsCount; i++) {
      let word = card[i] ? card[i].toLowerCase() : "";
      words[i] = isInMnemonic(word) ? word : "";
    }
    return words;
  });

  let lengths = getWordsOnEncode(seed, wordCount);
  
  for (let i = 0; i < lengths.length; i++) {
    //  если не хватает одного слова == тру
    if (checkThatAvailable(arr, i, lengths[i]) === (lengths[i] - 1)) {
      
      let temp_array = [];
      for (let j = 0; j < arr.length; j++) {
        if (j !== currentPostition && arr[j][i] !== undefined) {
          temp_array.push(arr[j][i]);
        }
      }
      // получаю слово для 3 карточки
      let encoded = getWord(temp_array);
      if (encoded !== undefined) {
        arr[currentPostition][i] = encoded;
      }
    }
  }
  if (!checkArr(seedArray, arr)) return false;

  return arr;
}

// если слов в карточке больше чем word-count
export const checkArr = (seedArray, arr) => {
  let mistakes = 0;

  for(let card = 0; card < seedArray.length; card++) {
    if(seedArray[card].length !== arr[card].length) {
      mistakes++;
    }
  }

  if(mistakes > 1) {
    return false;
  }

  return true;
}