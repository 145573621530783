import bip39 from "bip39";

import english from './mnemonic/languages/english';


/* 
	@param {array} - массив на основе которого вернут закодированное слово
*/
export const getWord = words => {
	var temp = 0;
	for (var i = 0; i < words.length; i++) {
		temp ^= english.indexOf(words[i].toLowerCase());
	}
	return english[temp];
};
/* 
	@param {string} seed - строка нашего сида
	@param {number} pages - говорит на сколько страниц надо разбить seed
	функция возвращает массив с закодированными словами
*/
export const getEncodedArray = (seed, pages) => {
	const max_encoded_word = Math.ceil(seed.length / (pages - 1));
	let encoded = "",
		encoded_words = [];

	for (let i = 0; i < max_encoded_word; i++) {
		if (i !== max_encoded_word - 1) {
			encoded = getWord(
				seed.slice((pages - 1) * i, (pages - 1) * (i + 1))
			);
			encoded_words.push(encoded);
		} else {
			encoded = getWord(seed.slice((pages - 1) * i, seed.length));
			encoded_words.push(encoded);
		}
	}

	return encoded_words;
};

export const getSptilSeed = (seed, pages) => {
	const seed_array = seed.trim().split(" ");
	let encoded_words = getEncodedArray(seed_array, pages),
		split_array = new Array(pages),
		word_count = 0,
		max_words_in_column = 24 / (pages - 1);

	if (24 % (pages - 1) !== 0) {
		max_words_in_column++;
	}

	// корректируем массив
	for (let card = 0; card < pages; card++) {
		split_array[card] = [];
	}

	for (let word = 0; word < max_words_in_column; word++) {
		for (let card = 0; card < pages; card++) {
			if (card === pages - 1) {
				split_array[card] = encoded_words;
			} else if (word_count < seed_array.length) {
				split_array[card][word] = seed_array[word_count];

				word_count++;
			}
		}
	}

	return split_array;
};

export const checkSeed = (seed) => bip39.validateMnemonic(seed);
export const getRandomSeed = () => bip39.generateMnemonic(160);
