import mnemonic from "./mnemonic";
const { isInMnemonic } = mnemonic; 

export const wordsCountAllowed = [12, 15, 18, 21, 24];

export const englishErrorText = "Enter valid seed words";
export const lengthErrorText = "Please enter either 12, 15, 21 or 24 words via single space";
export const pageErrorText = "Wrong number of words in cards";
export const notEnoughWordsErrorText = "Not enough words";
export const mistakesOnCardErrorText = "One or more cards have mistakes";

const getWordsLength = seed => seed.split(/(\s+)/).filter(e => e.trim().length).length;

export const checkSeedLength = seed => {
	return (wordsCountAllowed.indexOf(getWordsLength(seed)) > -1);
}

export const englishValid = seed => {
	const all = seed.split(/(\s+)/).filter(e => e.trim().length);
	return all.every(isInMnemonic);
}

export const canValidate = seedArray => {
	const filledCards = seedArray.filter(item => item);
	return filledCards.length === seedArray.length - 1;
};

export const checkSeedForErrors = (seedArray, wordCount, wordsOnColumn) => {
	const seed = seedArray.join(" ");
	const seedLength = getWordsLength(seed);
	if(!seedLength) return null;
	let error = null;
	seedArray.forEach((item, index) => {
		if (!englishValid(item) && !error) {
			error = englishErrorText;
		} else if (item && getWordsLength(item) !== wordsOnColumn[index] && !error) {
			error = pageErrorText;
		}
	});
	return error;
}