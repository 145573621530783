import React from "react";

import classNames from "../../lib/classNames";

const Caption = ({error, restore, children}) => {
  let classes = classNames("caption", {
  	"caption--invalid": error,
  	"caption--restore-seed": restore
  });
  return <p className={classes}>{error ? error : children}</p>
};

export default Caption;