export default {
	"pages": {
		"index": {
			"title": "the safest way to keep your seed phrase and private keys, compatible with Bitcoin, Ethereum and any other cryptocurrency",
			"description": "Paper Wallet is the safest way to keep your seed phrase and private keys, compatible with Bitcoin, Ethereum and any other cryptocurrency. BIP44 support."
		},
		"restore": {
			"title": "recover your seed"
		}
	},
	"intro": "Paper Wallet is the safest way to store your seed phrase and private keys. Our solution allows to split your seed phrase into several pages which you can print out and keep separately. You can lose one page and still be able to recover your seed.",
	"placeholder": "enter your seed",
	"validText": "Paper Wallet page does not store or send your data anywhere. During seed generation or split we recommend disconnecting your computer from the Internet."
}