import React from "react";

import classNames from "../../lib/classNames";

import "./button.css";

class Button extends React.Component {
	render(){
		const {className, children, color, ...props} = this.props;
		const classes = classNames("btn", className, {
			[`btn--${color}`]: color
		});
		return (
			<button className={classes} {...props}>
				{children}
			</button>
		)
	}
}

export default Button;