import React from "react";

import copyToClipboard from "../../lib/copyToClipboard";

import CopyButton from "../copyButton";

import "./page.css";

const Page = ({ pageNum, text }) => {
	const pageNumber = ++pageNum;
	return (
		<div className="page">
			<div className="page__position">
				<div className="page__headline">
					<div className="page__text">{pageNumber}</div>
				</div>
				<p className="page__textarea">{text}</p>
				<CopyButton className="page__copy" text={text} />
			</div>
		</div>
	);
};

export default Page;
