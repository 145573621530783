import React from "react";
import ReactSelect, { components } from "react-select";

import classNames from "../../lib/classNames";
import { wordsCountAllowed } from "../../lib/validation";

import "./pagesSelect.css";

const generate = Array.from(Array(6)).map((item, index) => ({value: index + 3, label: index + 3}));
const recover = Array.from(Array(6)).map((item, index) => ({value: index + 2, label: index + 2}));
const words = wordsCountAllowed.map(item => ({value: item, label: item}));

const wordsOption = [{
  label: "Choose your words count",
  options: words,
}];

const wordsStyles = {
  control: base => ({
    ...base,
    width: "36px"
  })
}

const generateStyles = {
  control: base => ({
    ...base,
    width: "28px"
  })
}

const GroupHeading = props => <components.GroupHeading {...props} />

const getValueObject = (value, arr) => {
  for (let i in arr) {
    if (value === arr[i].value) {
      return arr[i]
    }
  }
}

const Select = ({ text, className, ...props}) => {
  const classes = classNames("pages-select__block", className);
  return (
    <div className={classes}>
      <div><p className="pages-select__text">{text}</p></div>
      <ReactSelect
        components={GroupHeading}
        classNamePrefix="pages-select"
        isSearchable={false}
        {...props}
      />
    </div>
  )
}

const PagesSelect = (props) => {
  const { 
    isRestore,
    pages,
    select,
    wordCount,
    onChangePageCount,
    onChangeWordCount
  } = props;
  let selectSelect = select || generate;
  const generateOption = [{
    label: "How many pages will be generated from your seed phrase.",
    options: selectSelect,
  }];

  const recoverOption = [{
    label: "The minimum number of pages you will need to recover your seed.",
    options: selectSelect,
  }];
  const generateSelectPlaceholder = isRestore ? "generated pages": "pages to generate";
  return (
    <div className="pages-select__container">
      <div className="pages-select__container-position">
        <Select
          text={generateSelectPlaceholder}
          styles={generateStyles}
          defaultValue={generate[0]}
          value={getValueObject(pages, generate)}
          options={generateOption}
          onChange={onChangePageCount}
        />
        <Select
          text="need to recover"
          styles={generateStyles}
          defaultValue={recover[0]}
          value={getValueObject(pages - 1, recover)}
          isDisabled={true}
          options={recoverOption}
        />
        {isRestore && (
          <Select
            className="pages-select__block--words"
            text="total words"
            styles={wordsStyles}
            defaultValue={words[0]}
            value={getValueObject(wordCount, words)}
            options={wordsOption}
            onChange={onChangeWordCount}
          />
        )}
      </div>
    </div>
  )
};

export default PagesSelect;