import React from "react";

import PagesSelect from "../pagesSelect";
import Page from "../page";
import Button from "../button";

import "./generateSeed.css";

const Actions = ({ onPrint, onClear }) => (
  <div className="generate__title-with-btn">
    <Button color="green" onClick={onPrint}>
      print
    </Button>
    <Button color="white" onClick={onClear}>
      clear
    </Button>
  </div>
);

const Pages = ({ seed }) => (
  <div className="generate__list">
    <div className="generate__list-position">
      {seed.map((text, i) => (
        <Page key={i} pageNum={i} text={text} />
      ))}
    </div>
  </div>
);

const GenerateSeed = props => {
  const { value, seed, onPrint, onClear, pages, onChangePageCount } = props;
  return (
    <div className="container">
      <div className="generate__container">
        <Actions onPrint={onPrint} onClear={onClear} />
        <PagesSelect pages={pages} onChangePageCount={onChangePageCount} />
        <Pages seed={seed} />
      </div>
    </div>
  );
};

export default GenerateSeed;
